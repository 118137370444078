import React, { useState, useEffect } from 'react';
import './Homepage.css';

// Import icons from react-icons
import { FaTwitter, FaTwitch, FaDiscord } from 'react-icons/fa';

// Import images directly
import image1 from '../assets/image1.jpeg';
import image2 from '../assets/image2.jpeg';
import image3 from '../assets/image3.jpeg';
import image4 from '../assets/image4.jpeg';
import image5 from '../assets/image5.jpeg';
import image6 from '../assets/image6.jpeg';


import logo from '../assets/logo.jpeg';

const images = [image1, image2, image3, image4, image5, image6];

const Homepage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  // Function to handle logo click and open URL
  const handleLogoClick = () => {
    window.open("https://forms.gle/KXXvYKsqhKPKin4y8", "_blank");
  };

  return (
    <div className="homepage">
      <header className="header">
        <div className="social-icons">
          <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="icon-link">
            <FaTwitter className="social-icon" title="X" />
          </a>
          <a href="https://twitch.tv" target="_blank" rel="noopener noreferrer" className="icon-link">
            <FaTwitch className="social-icon" title="Twitch" />
          </a>
          <a href="https://discord.com" target="_blank" rel="noopener noreferrer" className="icon-link">
            <FaDiscord className="social-icon" title="Discord" />
          </a>
        </div>
        <img
          src={logo}
          alt="Club Logo"
          className="header-logo floating"
          onClick={handleLogoClick}
        />
      </header>
      <h1 className="neon-text">Welcome to <span className="highlight">Canna-Pixies Medical Cannabis Social Club e.V</span></h1>

      {/* Carousel with overlay hint */}
      <div className="carousel">
        <img
          src={images[currentIndex]}
          alt="Rotating Carousel"
          className="carousel-image"
        />
        <div className="carousel-overlay floating">
          <span className="carousel-hint">👇 Scroll to Discover ✨</span>
        </div>
      </div>

      {/* Additional Content */}
      <section className="intro">
        <p>
         We are the Berlin's premier community for high-quality, personalized medical cannabis. We’re pioneering a unique blend of wellness and technology, offering members exclusive access to premium products, personalized guidance, and digital innovation through NFTs and tokenized transactions. Join us to redefine your health journey and explore the future of medical cannabis!
          </p>
      </section>

      {/* <section className="features">
        <h2 className="neon-text">Why Join Us?</h2>
        <ul>
          <li>🎉 Epic Parties</li>
          <li>🧃 Custom Cocktails</li>
          <li>🌌 Late Night Vibes</li>
          <li>👥 Meet New People</li>
          <li>💎 Exclusive Perks</li>
        </ul>
      </section> */}

      <button className="cta-button floating" onClick={handleLogoClick}>
        Tap here!!
      </button>
    </div>
  );
};

export default Homepage;
