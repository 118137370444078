import React, { useState } from 'react';
import './AgeVerification.css';

const AgeVerification = ({ onConfirm }) => {
  const [birthdate, setBirthdate] = useState({ month: '', day: '', year: '' });
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBirthdate({ ...birthdate, [name]: value });
  };

  const calculateAge = () => {
    const { month, day, year } = birthdate;
    const birthDate = new Date(`${year}-${month}-${day}`);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const age = calculateAge();

    if (age >= 18) {
      if (remember) {
        localStorage.setItem('ageVerified', 'true'); // Remember choice
      }
      onConfirm(); // Proceed to the next step
    } else {
      setError('You must be 18 years or older to proceed.');
    }
  };

  return (
    <div className="age-verification-overlay">
      <div className="age-verification-card">
        <h1>Welcome.</h1>
        <p>Let's verify your age.</p>
        <form onSubmit={handleSubmit} className="age-form">
          <div className="age-inputs">
            <input
              type="text"
              name="month"
              placeholder="MM"
              maxLength="2"
              value={birthdate.month}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="day"
              placeholder="DD"
              maxLength="2"
              value={birthdate.day}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="year"
              placeholder="YYYY"
              maxLength="4"
              value={birthdate.year}
              onChange={handleChange}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="remember-me">
            <input
              type="checkbox"
              id="remember"
              checked={remember}
              onChange={() => setRemember(!remember)}
            />
            <label htmlFor="remember">Remember me</label>
          </div>
          <button type="submit" className="submit-button">Submit</button>
          <p className="age-warning">Valid government-issued identification required for pickup orders.</p>
        </form>
      </div>
    </div>
  );
};

export default AgeVerification;
