import React, { useState, useEffect } from 'react';
import CookieConsent from './Components/CookieConsent';
import AgeVerification from './Components/AgeVerification';
import Homepage from './Components/Homepage';
import StartPage from './Components/StartPage';

const App = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [ageVerified, setAgeVerified] = useState(false);
  const [showStartPage, setShowStartPage] = useState(false);

  useEffect(() => {
    // Check localStorage for cookie and age verification states
    const storedCookiesAccepted = localStorage.getItem('cookiesAccepted') === 'true';
    const storedAgeVerified = localStorage.getItem('ageVerified') === 'true';

    setCookiesAccepted(storedCookiesAccepted);
    setAgeVerified(storedAgeVerified);
  }, []);

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
    localStorage.setItem('cookiesAccepted', 'false'); // Store consent in localStorage
  };

  const handleAgeVerification = () => {
    setAgeVerified(true);
    localStorage.setItem('ageVerified', 'false'); // Store age verification in localStorage
  };

  // Apply blur if either consent or age verification is pending
  const isBlurred = !cookiesAccepted || !ageVerified;

  return (
    <div className={isBlurred ? 'blurred-content' : ''}>
      {!cookiesAccepted && <CookieConsent onAccept={handleAcceptCookies} />}
      {cookiesAccepted && !ageVerified && <AgeVerification onConfirm={handleAgeVerification} />}

      {/* Render the appropriate page based on state */}
      {showStartPage ? (
        <StartPage />
      ) : (
        <Homepage onLogoClick={() => setShowStartPage(true)} />
      )}
    </div>
  );
};

export default App;

