import React from 'react';
import './CookieConsent.css';

const CookieConsent = ({ onAccept }) => {
  return (
    <div className="cookie-consent">
      <p>We use cookies to improve your experience. Please accept to continue.</p>
      <button onClick={onAccept}>Accept Cookies</button>
    </div>
  );
};

export default CookieConsent;
