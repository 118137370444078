import React from 'react';
import './StartPage.css';

const StartPage = () => {
  return (
    <div className="start-page">
      <h2>About Our Social Club</h2>
      <p>Welcome to our community! We are dedicated to building a friendly and engaging environment.</p>
      <a href="https://forms.gle/KXXvYKsqhKPKin4y8" target="_blank" rel="noopener noreferrer">
        <button>To become a member - click here!</button>
      </a>
    </div>
  );
};

export default StartPage;
